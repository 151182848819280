@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
    background: linear-gradient(135deg, #000000, #1B263B, #0D1B2A, #2C3E50);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
    max-width: 100vw;
    width: 100vw;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.box {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: #4c62ef;
}

#nprogress .bar {
    background: #4c62ef !important;
    height: 5px !important;
}

#nprogress .spinner-icon {
    border-top-color: #4c62ef !important;
    border-left-color: #4c62ef !important;
}

#nprogress .peg {
    box-shadow: 0 0 10px #14151f, 0 0 5px #14151f !important;
}

.swiper {
    display: grid !important;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #2C3E50;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgba(76, 98, 239, 0.9);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(76, 98, 239, 1);
}